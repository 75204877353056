import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    destroy,
} from 'Redux/modules/admin/userOfferProducts/actions';

import {
    list as listProducts,
} from 'Redux/modules/admin/products/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            destroy: dispatch(destroy),
            listProducts: dispatch(listProducts),
        },
    }),
)(Component);