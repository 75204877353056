import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_USER_OFFER_PRODUCT, API_RESOURCE_PRODUCTS } from 'Consts/apiResources';
import { ADMIN_USER_OFFERS, ADMIN_USER_OFFER_PRODUCTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getName as getProductName } from 'Utils/product';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserOffeProductsEditor extends Component {
    static propTypes = {
        userOfferId: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            destroy: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
        products: [],
    };

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { userOfferId, actions, history } = this.props;

        return actions.create({
            ...formState,
            userOfferId,
            productId: fromSelectObject(formState.productId),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_USER_OFFER_PRODUCTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_USER_OFFER_PRODUCT].id }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
        });
    }

    onDestroy = () => {
        const { data, actions, history } = this.props;

        return actions.destroy({ id: data.id })
            .then(() => {
                history.push(
                    withVariables(
                        ADMIN_USER_OFFERS.path,
                    )
                );
            });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState, products } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-user-offers-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id),
                        title: 'Usuń produkt z zamówienia',
                        subtitle: 'Pozycja zostanie usunięte (wpłynie na cenę całego zamówienia)',
                        buttonProps: {
                            onClick: this.onDestroy,
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: nextFormState => this.setState(prevState => {
                            //Set default price from product
                            if(fromSelectObject(nextFormState.productId) !== fromSelectObject(prevState.formState.productId)) {
                                const productId = fromSelectObject(nextFormState.productId);
                                const currentProduct = products.find(product => productId && product.id === productId);

                                if(currentProduct) {
                                    nextFormState.priceGross = currentProduct.priceGross;
                                }
                            }
                            
                            return {
                                ...prevState,
                                formState: {
                                    ...nextFormState,
                                },
                            };
                        }),
                        elements: [{
                            isVisible: Boolean(!data),
                            type: 'select',
                            name: 'productId',
                            label: 'Produkt',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listProducts({ search: query }),
                                onMapResponse: response => {
                                    const products = response.payload[API_RESOURCE_PRODUCTS].elements;
                                    this.setState(prevState => ({ ...prevState, products }));
                                    return products;
                                },
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getProductName(element).label,
                                }),
                            },
                        }, {
                            type: 'input',
                            name: 'priceGross',
                            label: 'Cena brutto co cykl za 1 szt.',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'input',
                            name: 'quantity',
                            label: 'Ilość sztuk co cykl',
                            inputProps: {
                                type: 'number',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}